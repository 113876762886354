import React from "react";
import styled from "styled-components";

const colors = {
  red: "#F31010",
  orange: "#F79E5A",
  blue: "#33ADFF",
  green: "#4ECA73",
  gray: "#929292",
};

const Score = styled.div`
  padding: 2rem 0.5rem;
  color: white;
  font-size: 3rem;
  font-weight: 700;
  background-color: ${props => {
    const value = parseFloat(props.value);

    if (Number.isNaN(value)) {
      return colors.gray;
    }

    if (value < 0.25) {
      return colors.red;
    }

    if (value < 0.5) {
      return colors.orange;
    }

    if (value < 0.75) {
      return colors.blue;
    }

    if (value < 1) {
      return colors.green;
    }

    return colors.gray;
  }};
  text-align: center;
  width: 4em;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-radius: 0.3rem;
`;

export default ({ value }) => {
  return <Score value={value}>{value}</Score>;
};
