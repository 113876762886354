import React from "react";
import Layout from "../Layout";
import { SubmissionsPage } from "../../modules/Submissions";

const App = () => (
  <Layout>
    <SubmissionsPage />
  </Layout>
);

export default App;
