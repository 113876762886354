import React from "react";

const Svg = ({ name, title }) => {
  switch (name) {
    case "cross":
      return (
        <svg viewBox="0 0 24 24">
          <title>{title || name}</title>
          <path d="M18.984 6.422l-5.578 5.578 5.578 5.578-1.406 1.406-5.578-5.578-5.578 5.578-1.406-1.406 5.578-5.578-5.578-5.578 1.406-1.406 5.578 5.578 5.578-5.578z" />
        </svg>
      );

    case "check":
      return (
        <svg viewBox="0 0 24 24">
          <title>{title || name}</title>
          <path d="M9 16.172l10.594-10.594 1.406 1.406-12 12-5.578-5.578 1.406-1.406z" />
        </svg>
      );

    default:
      return false;
  }
};

export default ({ name, title, className }) => (
  <div className={className}>
    <Svg name={name} title={title} />
  </div>
);
