import React, { useEffect } from "react";
import { Button, H1 } from "@artificiallabs/artificial-ui";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import Header from "../../components/Header";
import { SubmissionsTable } from "../../containers";
import { getSubmissionsList } from "../../actions";
import { PageLoader } from "../../../../components";

const Submissions = ({ getSubmissions, isFetching, records }) => {
  useEffect(() => {
    getSubmissions();
  }, []);

  if (isFetching) {
    return <PageLoader />;
  }

  return (
    <>
      <Header>
        <H1>Submissions</H1>
        <Button as={Link} to="/create/step/upload" kind="primary">
          New Submission
        </Button>
      </Header>
      <SubmissionsTable data={records} />
    </>
  );
};

Submissions.propTypes = {
  getSubmissions: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
  records: PropTypes.array.isRequired,
};

const mapStateToProps = ({ file }) => ({
  isFetching: file.isFetching,
  records: file.records,
});

const mapDispatchToProps = dispatch => ({
  getSubmissions: () => dispatch(getSubmissionsList()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Submissions);
