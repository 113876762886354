import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button, Text } from "@artificiallabs/artificial-ui";
import FileUploadCard from "../FileUploadCard";
import * as actions from "../../../actions";
import { Loader } from "../../../../../components";
import { routes } from "../../../../../config";
import { setItem } from "../../../../../helpers";

const SubmitButton = styled(Button).attrs(() => ({
  kind: "primary",
  type: "submit",
}))`
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 3em;
`;

const LoaderWrapper = styled.div`
  text-align: center;
  margin-top: 2rem;
`;

const CardList = styled.ul`
  list-style: none;
  margin-bottom: 1rem;
`;

const CardListItem = styled.li`
  padding: 0 1em;
  margin-bottom: 1rem;
`;

const cleanFileName = fileName => fileName.replace(/\s/g, "_");

const FileUploadForm = ({ uploadFile, isUploading, history }) => {
  const [files, setFiles] = useState({
    lossRun: undefined,
    exposure: undefined,
  });

  const hasAllRequiredFiles = files.lossRun && files.exposure;

  const handleSubmit = async event => {
    event.preventDefault();

    try {
      await uploadFile(files.lossRun);
      await uploadFile(files.exposure);

      const lossRunFileName = cleanFileName(files.lossRun.filename);
      const exposureFileName = cleanFileName(files.exposure.filename);

      setItem("lossRunFileName", lossRunFileName);
      setItem("exposureFileName", exposureFileName);

      history.push(routes.lossRunValidation);
    } catch (err) {
      console.log(err);
    }
  };

  const handleRemove = type => setFiles({ ...files, [type]: undefined });

  const handleDrop = type => file => setFiles({ ...files, [type]: file });

  if (isUploading) {
    return (
      <LoaderWrapper>
        <Loader />
        <Text> Uploading... </Text>
      </LoaderWrapper>
    );
  }

  return (
    <form onSubmit={handleSubmit}>
      <CardList>
        <CardListItem>
          <FileUploadCard
            title="Loss Run Files"
            onDrop={handleDrop("lossRun")}
            uploadedFile={files.lossRun}
            onRemove={() => handleRemove("lossRun")}
            files={files}
          />
        </CardListItem>
        <CardListItem>
          <FileUploadCard
            title="Exposure Files"
            onDrop={handleDrop("exposure")}
            uploadedFile={files.exposure}
            onRemove={() => handleRemove("exposure")}
            files={files}
          />
        </CardListItem>
      </CardList>
      <SubmitButton
        kind="primary"
        isDisabled={!hasAllRequiredFiles || isUploading}
      >
        Process Files
      </SubmitButton>
    </form>
  );
};

const mapStateToProps = ({ file }) => ({
  isUploading: file.isUploading,
});

const mapDispatchToProps = {
  uploadFile: actions.uploadFile,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(FileUploadForm));
