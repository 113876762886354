export const UPLOAD_FILE = "UPLOAD_FILE";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAILURE = "UPLOAD_FILE_FAILURE";

export const GET_FILE_IMAGES = "GET_FILE_IMAGES";
export const GET_FILE_IMAGES_SUCCESS = "GET_FILE_IMAGES_SUCCESS";
export const GET_FILE_IMAGES_FAILURE = "GET_FILE_IMAGES_FAILURE";

export const GET_OCR = "GET_OCR";
export const GET_OCR_SUCCESS = "GET_OCR_SUCCESS";
export const GET_OCR_FAILURE = "GET_OCR_FAILURE";

export const START_OVER = "START_OVER";

export const FETCH_SUBMISSIONS = "FETCH_SUBMISSIONS";
export const FETCH_SUBMISSIONS_FAILURE = "FETCH_SUBMISSIONS_FAILURE";
export const FETCH_SUBMISSIONS_SUCCESS = "FETCH_SUBMISSIONS_SUCCESS";
