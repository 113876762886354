import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Chart from "chart.js";
import { get } from "lodash";

const CanvasWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  &:after {
    position: absolute;
    content: "";
    height: 2px;
    background-color: ${({ theme }) => theme.colors.red};
    top: 70%;
    left: 14%;
    right: 0;
  }
`;

// const yLabels = {
//   0: "0",
//   15000: "15k",
//   30000: "30k",
//   60000: "60k",
//   120000: "120k",
// };

const BarChart = ({ data }) => {
  const ref = useRef();

  useEffect(() => {
    const barChart = new Chart(ref.current, {
      type: "bar",
      data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 1500,
        // scales: {
        //   yAxes: [
        //     {
        //       ticks: {
        //         max: 110,
        //         callback: value => get(yLabels, value, ""),
        //       },
        //     },
        //   ],
        // },
      },
    });
  });

  return (
    <CanvasWrapper>
      <canvas ref={ref} />
    </CanvasWrapper>
  );
};

export default BarChart;
