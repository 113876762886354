import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { H2, Text, Card, Button } from "@artificiallabs/artificial-ui";
import Icon from "./icon.svg";
import FileSvg from "./file.svg";

const DropZone = styled.div`
  background-color: #f8f9f9;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 3rem;
  cursor: pointer;
  height: 100%;
`;

const Blue = styled.span`
  color: ${({ theme }) => theme.colors.blue};
`;

const SH2 = styled(H2)`
  margin-bottom: 0.75rem;
`;

const DropIcon = styled.img.attrs(() => ({
  src: Icon,
}))`
  margin-bottom: 1rem;
`;

const FileIcon = styled.img.attrs(() => ({
  src: FileSvg,
}))`
  margin-right: 0.5rem;
`;

const FileCardContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const RemoveButton = styled(Button).attrs(() => ({
  size: "small",
}))``;

const Centered = styled.div`
  display: flex;
  align-items: center;
`;

const Inner = styled.div`
  height: 15em;
`;

const FileUploadCard = ({ onDrop, onRemove, uploadedFile, title, files }) => {
  const handleDrop = useCallback(
    acceptedFiles => {
      const file = acceptedFiles[0];

      const reader = new FileReader();
      reader.onload = () => {
        onDrop({
          filename: file.name,
          base: reader.result.split(",")[1],
        });
      };
      reader.readAsDataURL(file);
    },
    [files],
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
  });

  return (
    <Card minWidth="0">
      <Card.Header>{title}</Card.Header>
      <Card.Content>
        <Inner>
          {!uploadedFile && (
            <DropZone {...getRootProps()}>
              <DropIcon />
              <input {...getInputProps()} multiple={false} />
              <SH2>Drag & Drop to upload</SH2>
              <Text>
                or <Blue>browse</Blue> to choose your file(s)
              </Text>
            </DropZone>
          )}
          {uploadedFile && (
            <FileCardContent>
              <Centered>
                <FileIcon />
                {uploadedFile.filename}
              </Centered>
              <RemoveButton onClick={onRemove}> Remove </RemoveButton>
            </FileCardContent>
          )}
        </Inner>
      </Card.Content>
    </Card>
  );
};

export default FileUploadCard;
