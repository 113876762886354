export const locationInformationMock = [
  {
    full_address:
      "Canton Estates, LTD Apartments, 388 Ricks Drive, Canton, MS, 39046, USA",
    address_found: true,
    tiv: "8944430.0",
    pb_address: {
      geo_location: {
        lat: 32.615964,
        long: -90.049122,
      },
      name_or_location: "CANTON ESTATES, LTD APARTMENTS",
      address: "388 RICKS DR",
      city: "MS",
      state: "CANTON",
      country: "USA",
      post_code: "39046",
    },
    select_addresses: null,
  },
  {
    full_address:
      "Cedar Cove Apartments, LLC, 107 Clinton Blvd., Clinton, MS, 39056, USA",
    address_found: true,
    tiv: "5424061.0",
    pb_address: {
      geo_location: {
        lat: 32.335181,
        long: -90.316349,
      },
      name_or_location: "CEDAR COVE APARTMENTS, LLC",
      address: "107 CLINTON BLVD",
      city: "MS",
      state: "CLINTON",
      country: "USA",
      post_code: "39056",
    },
    select_addresses: null,
  },
  {
    full_address: ", 110 Lincoln Green, Starkville, MS, 39759, USA",
    address_found: false,
    tiv: "6262918.0",
    pb_address: {
      geo_location: {
        lat: null,
        long: null,
      },
      name_or_location: null,
      address: null,
      city: null,
      state: null,
      country: null,
      post_code: null,
    },
    select_addresses: [
      {
        geo_location: {
          lat: 33.450249,
          long: -88.806593,
        },
        name_or_location: "COLLEGE STATION APTS",
        address: "110 LINCOLN GRN",
        city: "STARKVILLE",
        state: "MS",
        country: "USA",
        post_code: "39759",
      },
      {
        geo_location: {
          lat: 33.450249,
          long: -88.806593,
        },
        name_or_location: "COLLEGE STATION APTS",
        address: "110 LINCOLN GRN",
        city: "STARKVILLE",
        state: "MS",
        country: "USA",
        post_code: "39759",
      },
    ],
  },
  {
    full_address: ", 110 Lincoln Green, Starkville, MS, 39759, USA",
    address_found: false,
    tiv: "6262918.0",
    pb_address: {
      geo_location: {
        lat: null,
        long: null,
      },
      name_or_location: null,
      address: null,
      city: null,
      state: null,
      country: null,
      post_code: null,
    },
    select_addresses: [
      {
        geo_location: {
          lat: 33.450249,
          long: -88.806593,
        },
        name_or_location: "COLLEGE STATION APTS",
        address: "110 LINCOLN GRN",
        city: "STARKVILLE",
        state: "MS",
        country: "USA",
        post_code: "39759",
      },
      {
        geo_location: {
          lat: 33.450249,
          long: -88.806593,
        },
        name_or_location: "COLLEGE STATION APTS",
        address: "110 LINCOLN GRN",
        city: "STARKVILLE",
        state: "MS",
        country: "USA",
        post_code: "39759",
      },
    ],
  },
];

export const locationRiskScoreMock = {
  overall_risk_score: "0.56",
  overall_claim_risk_score: "N/A",
  overall_location_risk_score: "0.56",
  location_risk_data: [
    {
      risk_score: "0.42",
      risk_index: {
        crime: "Low",
        flood: "Moderate to Minimal",
        earthquake: "Low",
        fire: "N/A",
      },
      name_or_location: "CANTON ESTATES, LTD APARTMENTS",
      address: "388 RICKS DR",
      city: "MS",
      state: "CANTON",
      country: "USA",
      post_code: "39046",
    },
    {
      risk_score: "0.64",
      risk_index: {
        crime: "Highest",
        flood: "Moderate to Minimal",
        earthquake: "Low",
        fire: "N/A",
      },
      name_or_location: "CEDAR COVE APARTMENTS, LLC",
      address: "107 CLINTON BLVD",
      city: "MS",
      state: "CLINTON",
      country: "USA",
      post_code: "39056",
    },
    {
      risk_score: "0.63",
      risk_index: {
        crime: "Above Average",
        flood: "Moderate to Minimal",
        earthquake: "N/A",
        fire: "N/A",
      },
      name_or_location: "COLLEGE STATION APTS",
      address: "110 LINCOLN GRN",
      city: "STARKVILLE",
      state: "MS",
      country: "USA",
      post_code: "39759",
    },
  ],
};

export const submissionsMock = [
  {
    id: "CA000019843-07",
    insured: {
      name: "Artificial Labs",
    },
    createdAt: "2020-03-11",
    locationScore: "0.48",
    claimScore: "0.33",
    overallScore: "0.41",
  },
  {
    id: "CA000019843-07",
    insured: {
      name: "Chaucer",
    },
    createdAt: "2020-02-15",
    locationScore: "0.21",
    claimScore: "0.85",
    overallScore: "0.71",
  },
  {
    id: "CA000019843-07",
    insured: {
      name: "Argo US",
    },
    createdAt: "2020-02-21",
    locationScore: "0.89",
    claimScore: "0.72",
    overallScore: "0.80",
  },
];

export default locationInformationMock;
