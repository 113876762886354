import React from "react";
import moment from "moment";
import { Button } from "@artificiallabs/artificial-ui";
import { Link } from "react-router-dom";
import styled from "styled-components";

import { getBgColour, getTextColour } from "./helpers";

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Wrapper = styled.div`
  min-height: 3rem;
  padding: 0 0.625rem;
  display: flex;
  align-items: center;

  background-color: ${getBgColour};
  color: ${getTextColour};
`;

const percentageFormatter = value => (
  <Wrapper value={value}>
    <>{value * 100}</>%
  </Wrapper>
);

export default [
  { id: "insured.name", displayName: "Insured Name" },
  {
    id: "createdAt",
    displayName: "Date Submitted",
    formatter: value => moment(value).format("DD MMM YYYY"),
  },
  {
    id: "locationScore",
    displayName: "Location Score",
    formatter: percentageFormatter,
  },
  {
    id: "claimScore",
    displayName: "Claim Score",
    formatter: percentageFormatter,
  },
  {
    id: "overallScore",
    displayName: "Overall Score",
    formatter: percentageFormatter,
  },
  {
    id: "id",
    displayName: " ",
    formatter: id => (
      <ButtonWrapper>
        <Button as={Link} isSmall kind="neutral" to={`/submission/${id}`}>
          View Submission
        </Button>
      </ButtonWrapper>
    ),
  },
];
