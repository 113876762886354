const bgColors = {
  red: "#FFF0F6",
  orange: "#FFF4E6",
  blue: "#E7F5FF",
  green: "#EBFBEE",
  gray: "#929292",
};

export default props => {
  const value = parseFloat(props.value);

  if (Number.isNaN(value)) {
    return bgColors.gray;
  }

  if (value < 0.25) {
    return bgColors.green;
  }

  if (value < 0.5) {
    return bgColors.blue;
  }

  if (value < 0.75) {
    return bgColors.orange;
  }

  if (value < 1) {
    return bgColors.red;
  }

  return bgColors.gray;
};
