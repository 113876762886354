import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { H2, Button } from "@artificiallabs/artificial-ui";
import { connect } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { get } from "lodash";
import { TopSection, Accordion2, FullLoader } from "../../../../components";
import { routes } from "../../../../config";
import DocumentCanvas from "../../components/DocumentCanvas";
import Sidebar from "../../components/Sidebar";
import { getItem } from "../../../../helpers";

import * as actions from "../../actions";

const ContentContainer = styled.table`
  border-collapse: collapse;
  margin-bottom: 1rem;
`;

const ContentRow = styled.tr``;

const ContentText = styled.td`
  padding: 0.5rem;
  border: 1px solid #888;
`;

const StyledButton = styled(Button)`
  margin-bottom: 0.75rem;
  max-width: 3.75rem;
`;

const Wrap = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

const LossRunPreviewPage = ({ getFileImages, getOCR, images, ocr }) => {
  const [policyIndex, setPolicyIndex] = useState(0);
  const history = useHistory();
  const location = useLocation();
  const fileName = getItem("lossRunFileName");

  const fields = get(ocr, `[${policyIndex}].fields`, []);

  const selectedValues = fields.map(field => field.values[0]);

  useEffect(() => {
    getOCR(fileName);
  }, []);

  useEffect(() => {
    getFileImages(fileName, policyIndex);
  }, [policyIndex]);

  if (!ocr.length) {
    return <FullLoader />;
  }

  return (
    <>
      <TopSection>
        <H2>Your Document Preview</H2>
      </TopSection>
      <Wrap>
        <DocumentCanvas
          id="documentCanvas"
          fields={selectedValues}
          image={images}
        />
        <Sidebar>
          <StyledButton
            kind="primary"
            onClick={() => history.push(routes.locationValidation)}
          >
            Preview Correct
          </StyledButton>
          {ocr.map((policy, index) => (
            <Accordion2
              title={`Policy: ${policy.policy}`}
              isOpen={index === policyIndex}
              onClick={() => setPolicyIndex(index)}
            >
              <ContentContainer>
                {policy.fields.map(field => (
                  <ContentRow>
                    <ContentText>{field.phrase}</ContentText>
                    <ContentText>{get(field, "values[0].value")}</ContentText>
                  </ContentRow>
                ))}
              </ContentContainer>
            </Accordion2>
          ))}
        </Sidebar>
      </Wrap>
    </>
  );
};

LossRunPreviewPage.propTypes = {
  getFileImages: PropTypes.func.isRequired,
  getOCR: PropTypes.func.isRequired,
  images: PropTypes.object.isRequired,
  ocr: PropTypes.object.isRequired,
};

const mapStateToProps = ({ file }) => ({
  fileName: file.fileName,
  images: file.images,
  isLoading: file.isFetchingImages || file.isFetchingOCR,
  ocr: file.ocr,
});

const mapDispatchToProps = {
  getFileImages: actions.getFileImages,
  getOCR: actions.getOCR,
};

export default connect(mapStateToProps, mapDispatchToProps)(LossRunPreviewPage);
