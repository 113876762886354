import React from "react";
import { Loader } from "@artificiallabs/artificial-ui";
import styled from "styled-components";

const Wrapper = styled.div`
  margin-left: 15rem;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PageLoader = () => (
  <Wrapper>
    <Loader />
  </Wrapper>
);

export default PageLoader;
