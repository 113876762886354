import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Grid } from "@artificiallabs/artificial-ui";
import { getLocationInformation, postLocationRiskScore } from "../../../../api";
import { getItem, mapLocationData } from "../../../../helpers";
import { FullLoader } from "../../../../components";
import MapSection from "../../components/MapSection";
import {
  BarChart,
  DoughnutChart,
  LocationRiskScore,
  LossHistory,
} from "./components";

const { Row } = Grid;

const Columns = styled.div`
  display: flex;
`;

const Column = styled.div`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: ${({ size }) => {
    if (size === "1/3") return "33.333333%";
    if (size === "2/3") return "66.666667%";
    return "auto";
  }};
`;

const Wrapper = styled.div`
  padding-top: 4rem;
`;

const Card = styled.div`
  border: 1px solid #cdd4db;
  padding: 1.5rem;
`;

const Title = styled.div`
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1.5rem;
`;

const Insights = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [locationData, setLocationData] = useState([]);
  const [riskScoreData, setRiskScoreData] = useState([]);

  const initialFetch = async () => {
    const lossRunFileName = getItem("lossRunFileName");
    const selectedAddresses = getItem("selectedAddresses");
    const exposureFileName = getItem("exposureFileName");
    const locationRes = await getLocationInformation(exposureFileName);

    const mappedLocationData = mapLocationData(
      locationRes.data,
      selectedAddresses,
    );

    const riskScoreRes = await postLocationRiskScore({
      addresses: mappedLocationData,
      filename: lossRunFileName,
    });

    setLocationData(mappedLocationData);
    setRiskScoreData(riskScoreRes.data);
    setIsLoading(false);
  };

  useEffect(() => {
    initialFetch();
  }, []);

  if (isLoading) {
    return <FullLoader />;
  }

  const riskScoreDataWithTiv = riskScoreData.location_risk_data.map(
    (item, index) => ({
      ...locationData[index],
      ...item,
    }),
  );

  return (
    <Wrapper>
      <Row>
        <Columns>
          <Column size="1/3">
            <Card>
              <Title>Overall risk score</Title>
              <DoughnutChart value={riskScoreData.overall_risk_score} />
            </Card>
          </Column>
          <Column size="1/3">
            <Card>
              <Title>Overall location risk score</Title>
              <DoughnutChart
                value={riskScoreData.overall_location_risk_score}
              />
            </Card>
          </Column>
          <Column size="1/3">
            <Card>
              <Title>Overall claim risk score</Title>
              <DoughnutChart value={riskScoreData.overall_claim_risk_score} />
            </Card>
          </Column>
        </Columns>
      </Row>
      <Row>
        <Columns>
          <Column size="2/3">
            <LocationRiskScore rows={riskScoreDataWithTiv} />
          </Column>
          <Column size="1/3">
            <MapSection
              data={locationData.filter(address => address.lat && address.lng)}
            />
          </Column>
        </Columns>
      </Row>
      <Row>
        <Columns>
          <Column size="1/3">
            <BarChart
              data={{
                labels: riskScoreData.loss_run_summary.map(
                  item => item["Year of Account"],
                ),
                datasets: [
                  {
                    label: "TOTAL LOSS PER YEAR",
                    data: riskScoreData.loss_run_summary.map(item =>
                      parseFloat(item["Total Net Incurred"]),
                    ),
                    backgroundColor: "#F79E5A",
                    borderColor: "#F79E5A",
                    borderWidth: 1,
                  },
                ],
              }}
            />
          </Column>
          <Column size="2/3">
            <LossHistory data={riskScoreData.loss_run_summary} />
          </Column>
        </Columns>
      </Row>
    </Wrapper>
  );
};

export default Insights;
