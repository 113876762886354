import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import { Button, H2 } from "@artificiallabs/artificial-ui";
import { get } from "lodash";
import { Dropdown, TopSection, FullLoader } from "../../../../components";
import Sidebar from "../../components/Sidebar";
import * as actions from "../../actions";
import { routes } from "../../../../config";
import { getItem, setItem } from "../../../../helpers";
import DocumentCanvas from "../../components/DocumentCanvas";

const StyledButton = styled(Button)`
  margin-bottom: 0.75rem;
  max-width: 3.75rem;
`;

const Wrap = styled.div`
  display: flex;
  flex-wrap: nowrap;
`;

const LossRunValidationPage = ({
  getFileImages,
  getOCR,
  images,
  isLoading,
  ocr,
}) => {
  const history = useHistory();
  const policyIndex = 0;

  const fields = get(ocr, `[${policyIndex}].fields`, []);

  const [selection, setSelection] = useState({});

  const selectedValues = fields.map(
    (field, fieldIndex) => field.values[selection[fieldIndex] || 0],
  );

  const handleItemClick = fieldIndex => (e, value, valueIndex) => {
    setSelection({ ...selection, [fieldIndex]: valueIndex });
  };

  const handleMappingConfirmation = () => {
    history.push(routes.lossRunPreview);
  };

  useEffect(() => {
    const fileName = getItem("lossRunFileName");

    getOCR(fileName);
    getFileImages(fileName, 0);

    return () => setItem("selectedValues", selectedValues);
  }, []);

  if (isLoading) {
    return <FullLoader />;
  }

  return (
    <>
      <TopSection>
        <H2>Please verify the field mapping accuracy</H2>
      </TopSection>
      <Wrap>
        <DocumentCanvas
          id="documentCanvas"
          fields={selectedValues}
          image={images}
          isFetching={isLoading}
        />
        <Sidebar>
          <StyledButton kind="primary" onClick={handleMappingConfirmation}>
            Mapping Correct
          </StyledButton>
          {fields.map((field, fieldIndex) => (
            <Dropdown
              key={field.phrase}
              label={field.phrase}
              items={field.values}
              onItemClick={handleItemClick(fieldIndex)}
              value={selectedValues[fieldIndex]}
            />
          ))}
        </Sidebar>
      </Wrap>
    </>
  );
};

LossRunValidationPage.propTypes = {
  getFileImages: PropTypes.func.isRequired,
  getOCR: PropTypes.func.isRequired,
  images: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  ocr: PropTypes.array.isRequired,
};

const mapStateToProps = ({ file }) => ({
  images: file.images,
  isLoading: file.isFetchingImages || file.isFetchingOCR,
  ocr: file.ocr,
});

const mapDispatchToProps = {
  getFileImages: actions.getFileImages,
  getOCR: actions.getOCR,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(LossRunValidationPage);
