const colors = {
  red: "#C2255C",
  orange: "#E8590C",
  blue: "#1971C2",
  green: "#2F9E44",
  gray: "#929292",
};

export default props => {
  const value = parseFloat(props.value);

  if (Number.isNaN(value)) {
    return colors.gray;
  }

  if (value < 0.25) {
    return colors.green;
  }

  if (value < 0.5) {
    return colors.blue;
  }

  if (value < 0.75) {
    return colors.orange;
  }

  if (value < 1) {
    return colors.red;
  }

  return colors.gray;
};
