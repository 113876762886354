import React from "react";
import { Sidebar as DefaultSidebar } from "@artificiallabs/artificial-ui";
import { Link } from "react-router-dom";
import styled from "styled-components";

import logo from "./artificial-logo.svg";

const { Header, List, ListItem, Footer, Text, Icon } = DefaultSidebar;

const ArtificialLogo = styled.img`
  width: 100%;
  height: auto;
`;

const FooterContainer = styled.div`
  display: flex;
  margin-top: 1em;
`;

const StyledFooter = styled(Footer)`
  flex-direction: column;
  align-items: baseline;
  overflow: hidden;
  padding-top: 0;
`;

const StyledList = styled(List)`
  margin-top: 2rem;
`;

const Sidebar = () => (
  <DefaultSidebar>
    <Header>
      <ArtificialLogo src={logo} />
    </Header>
    <StyledList>
      <ListItem to="/" isActive>
        Submissions
      </ListItem>
      <ListItem to="/">Processed Docs</ListItem>
    </StyledList>
    <StyledFooter as={Link} to="/logout">
      <FooterContainer>
        <Icon name="user" />
        <Text>user@artificial.io</Text>
      </FooterContainer>
      <FooterContainer>
        <Icon name="logout" />
        <Text>Logout</Text>
      </FooterContainer>
    </StyledFooter>
  </DefaultSidebar>
);

export default Sidebar;
