import React from "react";
import styled from "styled-components";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";

import { Sidebar } from "..";

const Container = styled.div`
  padding: 1.75rem;
  flex: 1;
  position: relative;
  margin-left: 15rem;
  padding-bottom: 0;
  overflow: hidden;
`;

const Layout = ({ children }) => (
  <>
    <Sidebar />
    <Container>{children}</Container>
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default withRouter(Layout);
