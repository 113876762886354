import React from "react";
import { Switch, Route } from "react-router-dom";
import { CreateSubmission, SingleSubmission, Submissions } from "..";

const IndexPage = () => (
  <Switch>
    <Route path="/create" component={CreateSubmission} />
    <Route path="/submission/:id" component={SingleSubmission} />
    <Route path="/" component={Submissions} />
  </Switch>
);

export default IndexPage;
