export { default as theme } from "./theme";

export const baseApiURL = process.env.BASE_API_URL;

export const harsitApiURL = process.env.HARSIT_API_URL;

export const googleMapsApiKey = process.env.GOOGLE_MAPS_API_KEY;

export const scoringBucket = process.env.SCORING_BUCKET;

export const routes = {
  upload: "/create/step/upload",
  lossRunValidation: "/create/step/loss-run-validation",
  lossRunPreview: "/create/step/loss-run-preview",
  locationValidation: "/create/step/location-validation",
  insights: "/create/step/insights",
};

export const endpoints = {
  locationInformation: "/location/excel_file",
  locationRiskScore: "/location/risk_score",
};
