import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Chart from "chart.js";

const CanvasWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

const PercentageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
`;

const DoughnutChart = ({ value }) => {
  const ref = useRef();
  const parsed = parseFloat(value);
  const isValid = !Number.isNaN(parsed);
  const result = isValid ? parsed : 0;
  const valuePercent = Math.floor(result * 100);

  useEffect(() => {
    const myDoughnutChart = new Chart(ref.current, {
      type: "doughnut",
      data: {
        datasets: [
          {
            data: [valuePercent, 100 - valuePercent],
            backgroundColor: ["#33ADFF", "#cfcfcf"],
          },
        ],
      },
      options: {
        responsive: true,
        legend: {
          display: false,
        },
        cutoutPercentage: 80,
        title: {
          display: false,
        },
        animation: {
          animateScale: true,
          animateRotate: true,
        },
      },
    });
  });

  return (
    <CanvasWrapper>
      <canvas ref={ref} />
      <PercentageOverlay>
        {isValid ? `${valuePercent}%` : value}
      </PercentageOverlay>
    </CanvasWrapper>
  );
};

export default DoughnutChart;
