import {
  UPLOAD_FILE,
  UPLOAD_FILE_FAILURE,
  UPLOAD_FILE_SUCCESS,
  GET_FILE_IMAGES,
  GET_FILE_IMAGES_SUCCESS,
  GET_FILE_IMAGES_FAILURE,
  GET_OCR,
  GET_OCR_SUCCESS,
  GET_OCR_FAILURE,
  START_OVER,
  FETCH_SUBMISSIONS,
  FETCH_SUBMISSIONS_FAILURE,
  FETCH_SUBMISSIONS_SUCCESS,
} from "./actionType";

const initialState = {
  isUploading: false,
  fileName: "testacord.pdf",
  isFetchingImages: false,
  images: "",
  isFetchingOCR: false,
  ocr: [],
  isFetching: false,
  records: [],
};

export default (state = initialState, action) =>
  ({
    [UPLOAD_FILE]: {
      ...state,
      isUploading: true,
      fileName: null,
    },
    [UPLOAD_FILE_FAILURE]: {
      ...state,
      isUploading: false,
      error: action.error,
      fileName: null,
    },
    [UPLOAD_FILE_SUCCESS]: {
      ...state,
      isUploading: false,
      fileName: action.fileName,
    },
    [GET_FILE_IMAGES]: {
      ...state,
      isFetchingImages: true,
      images: null,
    },
    [GET_FILE_IMAGES_SUCCESS]: {
      ...state,
      isFetchingImages: false,
      images: action.images,
    },
    [GET_FILE_IMAGES_FAILURE]: {
      ...state,
      isFetchingImages: false,
      images: null,
    },
    [GET_OCR]: {
      ...state,
      isFetchingOCR: true,
      ocr: [],
    },
    [GET_OCR_SUCCESS]: {
      ...state,
      isFetchingOCR: false,
      ocr: action.ocr,
    },
    [GET_OCR_FAILURE]: {
      ...state,
      isFetchingOCR: false,
      ocr: [],
    },
    [FETCH_SUBMISSIONS]: {
      ...state,
      isFetching: true,
    },
    [FETCH_SUBMISSIONS_FAILURE]: {
      ...state,
      isFetching: false,
      errors: action.errors,
    },
    [FETCH_SUBMISSIONS_SUCCESS]: {
      ...state,
      isFetching: false,
      records: action.records,
    },
    [START_OVER]: initialState,
  }[action.type] || state);
