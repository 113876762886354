import React from "react";
import { Card, Grid, Text } from "@artificiallabs/artificial-ui";
import styled from "styled-components";
import { get } from "lodash";
import PropTypes from "prop-types";

const { Content, Header } = Card;
const { Column, Columns, Row } = Grid;

const BoldText = styled(Text)`
  font-weight: bold;
  margin-bottom: 0.75rem;
`;

const SubmissionDetails = ({ exposureFile, lossRunFile, submission }) => (
  <Card>
    <Header>Submission Details</Header>
    <Content>
      <Row>
        <Columns>
          <Column span="4">
            <BoldText>Submission ID</BoldText>
            <Text>{get(submission, "id")}</Text>
          </Column>
          <Column span="4">
            <BoldText>Insured name</BoldText>
            <Text>{get(submission, "insured.name")}</Text>
          </Column>
          <Column span="4">
            <BoldText>Date Submitted</BoldText>
            <Text>{get(submission, "createdAt")}</Text>
          </Column>
        </Columns>
      </Row>
      <Row>
        <Columns>
          <Column span="4">
            <BoldText>Loss Run File</BoldText>
            <Text>{lossRunFile}</Text>
          </Column>
          <Column span="4">
            <BoldText>Exposure File</BoldText>
            <Text>{exposureFile}</Text>
          </Column>
        </Columns>
      </Row>
    </Content>
  </Card>
);

SubmissionDetails.propTypes = {
  exposureFile: PropTypes.string.isRequired,
  lossRunFile: PropTypes.string.isRequired,
  submission: PropTypes.object.isRequired,
};

export default SubmissionDetails;
