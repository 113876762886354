import React from "react";
import { Table, Text } from "@artificiallabs/artificial-ui";
import styled from "styled-components";
import get from "lodash.get";

import Title from "../Title";
import { getUSDValue } from "../../../../../../helpers";

const Card = styled.div`
  border: 1px solid #cdd4db;
  padding: 1.5rem;
`;

const RecordWrapper = styled.div`
  display: flex;
  align-items: center;

  margin-top: ${({ withMarginTop }) => withMarginTop && "1rem"};
  margin-bottom: 0.375rem;
`;

const TextWithSpace = styled(Text)`
  margin-left: 1.75rem;
  margin-right: 3rem;
`;

const Cell = styled.div`
  padding: 0.7rem;
`;

const columns = [
  {
    id: "location",
    displayName: "Location #",
  },
  {
    id: "address",
    displayName: "Address",
  },
  {
    id: "tiv",
    displayName: "TIV",
  },
  {
    id: "risk_index.flood",
    displayName: "Flood",
  },
  {
    id: "risk_index.earthquake",
    displayName: "Quake",
  },
  {
    id: "risk_index.fire",
    displayName: "Fire",
  },
  {
    id: "risk_index.crime",
    displayName: "Crime",
  },
  {
    id: "risk_score",
    displayName: "Avg. Risk score",
  },
];

const LocationRiskScore = ({ rows }) => {
  const totalTiv = rows.reduce((prev, next) => prev + parseFloat(next.tiv), 0);

  return (
    <Card>
      <Title>Location Risk Score</Title>
      <Table
        isStriped
        columns={columns}
        rows={rows}
        renderData={(row, rowIndex) => column => {
          if (column.id === "location") {
            return <Cell>{rowIndex + 1}</Cell>;
          }

          if (column.id === "tiv") {
            const value = get(row, column.id);

            return <Cell>{getUSDValue(value)}</Cell>;
          }

          return get(row, column.id);
        }}
      />
      <RecordWrapper withMarginTop>
        <Text>
          Total TIV:{" "}
          <TextWithSpace as="span">{getUSDValue(totalTiv)}</TextWithSpace>
        </Text>
      </RecordWrapper>
    </Card>
  );
};

export default LocationRiskScore;
