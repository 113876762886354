export const setItem = (key, value) =>
  localStorage.setItem(key, JSON.stringify(value));

export const getItem = key => JSON.parse(localStorage.getItem(key));

export const getUSDValue = value =>
  Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(value);

export const getFullAddress = ({
  address,
  city,
  country,
  post_code: postCode,
  state,
}) => [address, city, state, postCode, country].filter(Boolean).join(", ");

export const mapLocationData = (data, selectedAddresses) =>
  data.map((address, addressIndex) => {
    const selectedAddressIndex = selectedAddresses[addressIndex];
    const suggestedAddresses = address.select_addresses || [];
    const selectedAddress = suggestedAddresses[selectedAddressIndex];
    const isAddressSelected = selectedAddressIndex !== undefined;

    const finalAddress = isAddressSelected
      ? selectedAddress
      : address.pb_address;

    return {
      ...finalAddress,
      tiv: address.tiv,
      lat: finalAddress.geo_location.lat,
      lng: finalAddress.geo_location.long,
    };
  });
