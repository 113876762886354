import React from "react";
import styled from "styled-components";
import { Grid } from "@artificiallabs/artificial-ui";
import PropTypes from "prop-types";
import { get } from "lodash";

import MapSection from "../../components/MapSection";
import {
  BarChart,
  DoughnutChart,
  LocationRiskScore,
  LossHistory,
} from "../../pages/Insights/components";

const { Row } = Grid;

const Columns = styled.div`
  display: flex;
`;

const Column = styled.div`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  width: ${({ size }) => {
    if (size === "1/3") return "33.333333%";
    if (size === "2/3") return "66.666667%";
    return "auto";
  }};
`;

const Wrapper = styled.div`
  padding-top: 4rem;
`;

const Card = styled.div`
  border: 1px solid #cdd4db;
  padding: 1.5rem;
`;

const Title = styled.div`
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 1.5rem;
`;

const SubmissionInsights = ({ locationData, riskScoreData }) => {
  const riskScoreDataWithTiv = get(riskScoreData, "location_risk_data", []).map(
    (item, index) => ({
      ...locationData[index],
      ...item,
    }),
  );

  return (
    <Wrapper>
      <Row>
        <Columns>
          <Column size="1/3">
            <Card>
              <Title>Overall risk score</Title>
              <DoughnutChart value={get(riskScoreData, "overall_risk_score")} />
            </Card>
          </Column>
          <Column size="1/3">
            <Card>
              <Title>Overall location risk score</Title>
              <DoughnutChart
                value={get(riskScoreData, "overall_location_risk_score")}
              />
            </Card>
          </Column>
          <Column size="1/3">
            <Card>
              <Title>Overall claim risk score</Title>
              <DoughnutChart
                value={get(riskScoreData, "overall_claim_risk_score")}
              />
            </Card>
          </Column>
        </Columns>
      </Row>
      <Row>
        <Columns>
          <Column size="2/3">
            <LocationRiskScore rows={riskScoreDataWithTiv} />
          </Column>
          <Column size="1/3">
            <MapSection
              data={locationData.filter(address => address.lat && address.lng)}
            />
          </Column>
        </Columns>
      </Row>
      <Row>
        <Columns>
          <Column size="1/3">
            <BarChart
              data={{
                labels: get(riskScoreData, "loss_run_summary", []).map(
                  item => item["Year of Account"],
                ),
                datasets: [
                  {
                    label: "TOTAL LOSS PER YEAR",
                    data: get(riskScoreData, "loss_run_summary", []).map(item =>
                      parseFloat(item["Total Net Incurred"]),
                    ),
                    backgroundColor: "#F79E5A",
                    borderColor: "#F79E5A",
                    borderWidth: 1,
                  },
                ],
              }}
            />
          </Column>
          <Column size="2/3">
            <LossHistory data={get(riskScoreData, "loss_run_summary", [])} />
          </Column>
        </Columns>
      </Row>
    </Wrapper>
  );
};

SubmissionInsights.propTypes = {
  locationData: PropTypes.array.isRequired,
  riskScoreData: PropTypes.object.isRequired,
};

export default SubmissionInsights;
