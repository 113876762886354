import React from "react";
import styled from "styled-components";
import { Loader } from "..";

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 8rem);
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const FullLoader = () => (
  <Wrapper>
    <Loader />
    Processing File...
  </Wrapper>
);

export default FullLoader;
