import React from "react";
import styled from "styled-components";
import { Grid, Icon, Text } from "@artificiallabs/artificial-ui";

import Title from "../Title";

const { Column, Columns, Row } = Grid;

const CardTitle = styled(Text)`
  font-size: 0.6875rem;
  text-transform: uppercase;
`;

const CompanyCardWrapper = styled(Column)`
  border: 1px solid ${({ theme }) => theme.colors.tableBorder};
  padding: 1rem;
  margin-left: 0.5rem;
`;

const FooterSection = styled.div`
  position: relative;
  padding-top: 1rem;

  &::before {
    content: "";
    position: absolute;
    background-color: ${({ theme }) => theme.colors.tableBorder};
    height: 1px;
    top: 0;
    left: -1rem;
    right: -1rem;
  }
`;

const GreenText = styled(Text)`
  color: ${({ theme }) => theme.colors.green};
  display: inline-flex;
  cursor: pointer;
`;

const LogoutIcon = styled(Icon).attrs(() => ({
  name: "logout",
}))`
  height: 0.625rem;
  width: 0.7rem;
  fill: ${({ theme }) => theme.colors.green};
  margin-left: 0.375rem;
  margin-top: 0.125rem;
`;

const Value = styled(Text)`
  color: #000;
  line-height: 20px;
  margin-bottom: 0.75rem;
`;

const CompanyDetails = () => (
  <CompanyCardWrapper span="6">
    <Row>
      <CardTitle>Company Details</CardTitle>
    </Row>
    <Columns style={{ paddingBottom: "1rem" }}>
      <Column>
        <Title> Company Name </Title>
        <Value> Artificial Labs Ltd </Value>

        <Title> COMPANY TYPE </Title>
        <Value> Private limited Company </Value>
      </Column>
      <Column>
        <Title> COMPANY REG ADDRESS </Title>
        <Value>
          Bourne House, <br />
          475 Godstone <br />
          Road, <br />
          Whyteleafe, <br />
          Surrey, CR3 0BL
        </Value>
      </Column>
      <Column>
        <Title> COMPANY STATUS </Title>
        <Value> Active </Value>

        <Title> NATURE OF BUSINESS </Title>
        <Value>62090 - Other information technology service activities</Value>
      </Column>
    </Columns>
    <FooterSection>
      <GreenText>
        Verified on Companies House <LogoutIcon />
      </GreenText>
    </FooterSection>
  </CompanyCardWrapper>
);

export default CompanyDetails;
