import axios from "axios";
import { harsitApiURL as baseURL, endpoints, scoringBucket } from "../config";
import { locationInformationMock, locationRiskScoreMock } from "./mocks";

const sleep = timeout => new Promise(resolve => setTimeout(resolve, timeout));

const request = method => async ({ url, data, params }) =>
  axios({
    baseURL,
    url,
    data,
    method,
    params,
  });

export const getRequest = request("get");

export const postRequest = request("post");

export const patchRequest = request("patch");

export const deleteRequest = request("delete");

export const getLocationInformation = async fileName => {
  try {
    return await getRequest({
      url: endpoints.locationInformation,
      params: {
        file_path: fileName,
        s3_bucket: scoringBucket,
        max_candidates: 5,
      },
    });

    await sleep(500);

    return { data: locationInformationMock };
  } catch (err) {
    throw new Error(err);
  }
};

export const postLocationRiskScore = async data => {
  try {
    return await postRequest({
      url: endpoints.locationRiskScore,
      data,
    });

    await sleep(500);

    return { data: locationRiskScoreMock };
  } catch (err) {
    throw new Error(err);
  }
};
