import styled from "styled-components";

export default styled.div`
  height: 7.125rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.tableBorder};
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
`;
