import React, { Fragment } from "react";
import styled from "styled-components";
import { Icon } from "@artificiallabs/artificial-ui";

const Wrapper = styled.button`
  padding: 1rem 1rem;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.colors.blueGray};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 0.75rem;
  cursor: pointer;
  background-color: white;
  font-family: ${({ theme }) => theme.fonts.inter.medium} !important;
  font-size: 15px;

  &:focus {
    outline: none;
  }
`;

const Content = styled.div``;

const Arrow = styled(Icon).attrs(({ isOpen }) => ({
  name: isOpen ? "chevron-down" : "chevron-right",
}))`
  width: 0.8rem;
  height: auto;
  margin-right: 1rem;

  path {
    fill: ${({ theme, isExpanded }) =>
      isExpanded ? theme.colors.blue : theme.colors.darkBlueGray};
  }
`;

const Accordion = ({ children, title, isOpen, onClick }) => {
  const handleClick = e => {
    e.preventDefault();

    onClick();
  };

  return (
    <Fragment>
      <Wrapper onClick={handleClick}>
        <Arrow isOpen={isOpen} />
        {title}
      </Wrapper>
      {isOpen && <Content>{children}</Content>}
    </Fragment>
  );
};

export default Accordion;
