import React from "react";
import styled from "styled-components";
import { H1 } from "@artificiallabs/artificial-ui";
import FileUploadForm from "./FileUploadForm";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 3rem 5rem;
`;

const Title = styled(H1)`
  align-self: center;
  margin-bottom: 3.5rem;
`;

const Upload = () => (
  <Wrapper>
    <Title>Upload your files</Title>
    <FileUploadForm />
  </Wrapper>
);

export default Upload;
