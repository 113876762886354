import React from "react";
import { Table } from "@artificiallabs/artificial-ui";
import PropTypes from "prop-types";
import { get } from "lodash";

import columns from "./columns";

const { Cell } = Table;

const SubmissionsTable = ({ data }) => (
  <Table
    rows={data}
    columns={columns}
    isStriped
    renderData={row => column => {
      const { formatter, id: columnId } = column;
      const value = get(row, columnId) || row[column];

      if (
        ["id", "locationScore", "claimScore", "overallScore"].includes(columnId)
      ) {
        return formatter(value);
      }

      return (
        <Cell>
          {formatter && formatter(value)}
          {!formatter && value}
        </Cell>
      );
    }}
  />
);

SubmissionsTable.propTypes = {
  data: PropTypes.array.isRequired,
};

export default SubmissionsTable;
