import React from "react";
import { connect } from "react-redux";
import { Button } from "@artificiallabs/artificial-ui";
import styled from "styled-components";
import { useRouteMatch, useHistory } from "react-router-dom";

import PropTypes from "prop-types";
import { Tab, Tabs } from "../Tab";
import { routes } from "../../config";
import * as actions from "../../modules/Submissions/actions";

const Wrapper = styled.div`
  display: flex;
  margin: -1.75rem;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.lightGray};
  padding-right: 1rem;
  padding-top: 0.25rem;
  border-bottom: 1px solid ${({ theme }) => theme.colors.tableBorder};
  margin-bottom: 1.125rem;
`;

const TabsWrapper = styled.div`
  margin-left: auto;
`;

const StartOverButton = styled(Button)`
  margin-left: auto;
`;

const tabs = [
  { label: "Upload", route: routes.upload },
  { label: "Loss Run Validation", route: routes.lossRunValidation },
  { label: "Loss Run Preview", route: routes.lossRunPreview },
  { label: "Location Validation", route: routes.locationValidation },
  { label: "Risk Insights", route: routes.insights },
];

const Header = ({ startOver }) => {
  const history = useHistory();
  const activeTabIndex = tabs.findIndex(tab => useRouteMatch(tab.route));

  return (
    <Wrapper>
      <TabsWrapper>
        <Tabs>
          {tabs.map((tab, index) => (
            <Tab
              key={tab.route}
              active={useRouteMatch(tab.route)}
              completed={index < activeTabIndex}
            >
              {tab.label}
            </Tab>
          ))}
        </Tabs>
      </TabsWrapper>
      <StartOverButton
        isSmall
        kind="neutral"
        onClick={() => {
          startOver();
          localStorage.clear();
          history.push(routes.upload);
        }}
      >
        Start Over
      </StartOverButton>
    </Wrapper>
  );
};

const mapDispatchToProps = {
  startOver: actions.startOver,
};

Header.propTypes = {
  startOver: PropTypes.func.isRequired,
};

export default connect(null, mapDispatchToProps)(Header);
