import React, { useState } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Button, Overlay, Text } from "@artificiallabs/artificial-ui";
import { get } from "lodash";

const getIndicatorStyles = value => {
  if (value < 40) {
    return css`
      color: ${({ theme }) => theme.colors.red};
      background-color: #fee3e3;
    `;
  }

  if (value < 60) {
    return css`
      color: ${({ theme }) => theme.colors.mediumGray};
      background-color: ${({ theme }) => theme.colors.lightGray};
    `;
  }

  return css`
    color: #178137;
    background-color: #dcf4e3;
  `;
};

const commonStyles = css`
  padding: 0.5rem 0.3125rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DropdownButton = styled(Button)`
  ${commonStyles};
  padding-right: 2.288rem;
  text-align: left;
  border-radius: 3px;
  border-color: ${({ theme }) => theme.colors.blueGray};

  &:focus {
    z-index: 3;
  }
`;

const DropdownItem = styled(Button)`
  ${commonStyles};
  width: 100%;
  border-top-width: 0;
  border-radius: 0;
  text-align: left;
  border-bottom-width: 0.063rem;
  border-bottom-style: solid;
  border-bottom-color: ${({ theme }) => theme.colors.blueGray};
  border-color: ${({ theme }) => theme.colors.blueGray};
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.lightGray};
    box-shadow: none;
    transform: none;
  }

  &:last-child {
    border-bottom-right-radius: 0.188rem;
    border-bottom-left-radius: 0.188rem;
  }

  &:focus {
    z-index: 2;
  }
`;

const Wrapper = styled.div`
  position: relative;
  cursor: pointer;
  display: inline-flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
`;

const Menu = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.colors.blueGray};
  top: 5.35rem;
  left: 0;
  right: 0;
  z-index: 2;
  color: ${({ theme }) => theme.colors.blueGray};
  border-radius: 3px;

  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 5px;
    left: 1.4375rem;
    width: 10px;
    height: 10px;
    background: #ffffff;
    border-right: 1px solid ${({ theme }) => theme.colors.blueGray};
    border-bottom: 1px solid ${({ theme }) => theme.colors.blueGray};
    transform: translateY(-100%) rotate(-135deg);
  }
`;

const Label = styled(Text)`
  margin-bottom: 0.8125rem;
  text-transform: capitalize;
`;

const ConfidenceIndicator = styled.div`
  ${({ confidence }) => getIndicatorStyles(confidence)}
  padding: 0.5rem 1rem;
  text-align: center;
  border-radius: 3px;
`;

const StyledText = styled(Text)`
  margin-left: 0.5625rem;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const DisabledText = styled(StyledText)`
  margin-left: 0.5625rem;
  color: ${({ theme }) => theme.colors.mediumGray};
`;

const withPrecision = value => Number(value).toFixed(2);

const Dropdown = ({ items, label, onItemClick, value }) => {
  const [isVisible, setVisibility] = useState(false);
  const icon = isVisible ? "chevron-up" : "chevron-down";

  const toggleVisibility = () => setVisibility(!isVisible);
  const hideDropdown = () => setVisibility(false);

  const handleItemClick = (item, index) => event => {
    hideDropdown();
    onItemClick(event, item, index);
  };

  if (!items || !items.length) {
    return (
      <Wrapper>
        <Label>{label}</Label>
        <DropdownButton iconName={icon} iconPosition="right" isDisabled>
          <DisabledText>No proposed values...</DisabledText>
        </DropdownButton>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Label>{label}</Label>
      <DropdownButton
        iconName={items.length > 1 ? icon : undefined}
        iconPosition="right"
        onClick={items.length > 1 ? toggleVisibility : null}
        isVisible={isVisible}
      >
        <StyledText>{get(value, "key")}</StyledText>
        <ConfidenceIndicator confidence={get(value, "confidence", 0)}>
          {withPrecision(get(value, "confidence", 0))}% Match
        </ConfidenceIndicator>
      </DropdownButton>
      {isVisible && (
        <Overlay onClick={hideDropdown} backgroundColor="transparent" />
      )}
      {isVisible && (
        <Menu>
          {items &&
            items.length &&
            items.map((item, index) => (
              <DropdownItem
                key={JSON.stringify(item)}
                onClick={handleItemClick(item, index)}
              >
                <StyledText>{item.key}</StyledText>
                <ConfidenceIndicator confidence={get(item, "confidence", 0)}>
                  {withPrecision(get(item, "confidence", 0))}% Match
                </ConfidenceIndicator>
              </DropdownItem>
            ))}
        </Menu>
      )}
    </Wrapper>
  );
};

Dropdown.propTypes = {
  items: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  onItemClick: PropTypes.func.isRequired,
  value: PropTypes.object.isRequired,
};

export default Dropdown;
