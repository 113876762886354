import axios from "axios";
import {
  UPLOAD_FILE,
  UPLOAD_FILE_FAILURE,
  UPLOAD_FILE_SUCCESS,
  GET_FILE_IMAGES,
  GET_FILE_IMAGES_SUCCESS,
  GET_FILE_IMAGES_FAILURE,
  GET_OCR,
  GET_OCR_SUCCESS,
  GET_OCR_FAILURE,
  START_OVER,
  FETCH_SUBMISSIONS,
  FETCH_SUBMISSIONS_FAILURE,
  FETCH_SUBMISSIONS_SUCCESS,
} from "./actionType";

import { baseApiURL } from "../../config";
import { getRequest } from "../../api";
import { submissions } from "../../api/endpoints";

import { submissionsMock } from "../../api/mocks";

const timeout = ms => new Promise(resolve => setTimeout(resolve, ms));

const uploadFileBegin = () => ({
  type: UPLOAD_FILE,
});

const uploadFileSuccess = fileName => ({
  type: UPLOAD_FILE_SUCCESS,
  fileName,
});

const uploadFileFailure = error => ({
  type: UPLOAD_FILE_FAILURE,
  error,
});

export const uploadFile = file => async dispatch => {
  dispatch(uploadFileBegin());

  try {
    const filename = file.filename.replace(/\s/g, "_");

    const response = await axios.post(`${baseApiURL}/decode`, {
      ...file,
      filename,
    });

    dispatch(uploadFileSuccess(filename));
  } catch (err) {
    dispatch(uploadFileFailure(err));

    throw new Error(err);
  }
};

const getFileImagesBegin = () => ({
  type: GET_FILE_IMAGES,
});

const getFileImagesSuccess = images => ({
  type: GET_FILE_IMAGES_SUCCESS,
  images,
});

const getFileImagesFailure = error => ({
  type: GET_FILE_IMAGES_FAILURE,
  error,
});

export const getFileImages = (fileName, page) => async dispatch => {
  dispatch(getFileImagesBegin());

  try {
    const { data } = await axios.get(
      `${baseApiURL}/metadata/image/${fileName}?page=${page}`,
    );

    dispatch(getFileImagesSuccess(data.image));
  } catch (err) {
    if (err.response && err.response.status === 404) {
      await timeout(5000);
      return dispatch(getFileImages(fileName, page));
    }

    dispatch(getFileImagesFailure(err));

    throw new Error(err);
  }
};

const getOCRBegin = () => ({
  type: GET_OCR,
});

const getOCRSuccess = ocr => ({
  type: GET_OCR_SUCCESS,
  ocr,
});

const getOCRFailure = error => ({
  type: GET_OCR_FAILURE,
  error,
});

export const getOCR = fileName => async dispatch => {
  dispatch(getOCRBegin());

  try {
    const response = await axios.get(
      `${baseApiURL}/metadata/document/${fileName}`,
    );

    const ocr = JSON.parse(response.data.document.output);

    dispatch(getOCRSuccess(ocr));
  } catch (err) {
    if (err.response && err.response.status === 404) {
      await timeout(5000);
      return dispatch(getOCR(fileName));
    }

    dispatch(getOCRFailure(err));

    throw new Error(err);
  }
};

export const startOver = () => dispatch => dispatch({ type: START_OVER });

export const fetchSubmissions = () => ({
  type: FETCH_SUBMISSIONS,
});

export const fetchSubmissionsError = errors => ({
  type: FETCH_SUBMISSIONS_FAILURE,
  errors,
});

export const fetchSubmissionsSuccess = records => ({
  type: FETCH_SUBMISSIONS_SUCCESS,
  records,
});

export const getSubmissionsList = () => async dispatch => {
  dispatch(fetchSubmissions());

  try {
    // const response = await getRequest(submissions)();
    const response = submissionsMock;
    await timeout(750);
    dispatch(fetchSubmissionsSuccess(response));
    return response;
  } catch (errors) {
    dispatch(fetchSubmissionsError(errors));
    throw errors;
  }
};
