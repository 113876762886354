export default [
  {
    geo_location: { lat: 32.615964, long: -90.049122 },
    name_or_location: "CANTON ESTATES",
    address: "388 RICKS DR",
    city: "CANTON",
    state: "MS",
    country: "USA",
    post_code: "39046",
    tiv: "8944430.0",
    lat: 32.615964,
    lng: -90.049122,
  },
  {
    geo_location: { lat: 32.335181, long: -90.316349 },
    name_or_location: "CEDAR COVE APTS",
    address: "107 CLINTON BLVD",
    city: "CLINTON",
    state: "MS",
    country: "USA",
    post_code: "39056",
    tiv: "5424061.0",
    lat: 32.335181,
    lng: -90.316349,
  },
  {
    geo_location: { lat: 33.450249, long: -88.806593 },
    name_or_location: "CHARLESTON PLACE, LLC",
    address: "110 LINCOLN GRN",
    city: "STARKVILLE",
    state: "MS",
    country: "USA",
    post_code: "39759",
    tiv: "6262918.0",
    lat: 33.450249,
    lng: -88.806593,
  },
];
