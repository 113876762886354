import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import GoogleMapsLoader from "google-maps";
import { googleMapsApiKey } from "../../../../config";

GoogleMapsLoader.KEY = googleMapsApiKey;
GoogleMapsLoader.LIBRARIES = ["places"];
GoogleMapsLoader.VERSION = "weekly";

const Map = styled.div`
  width: 100%;
  height: 100%;
`;

export default ({ data, centerLocationIndex, className }) => {
  const ref = useRef();

  const handleMapLoad = () => {
    const node = ref.current;
    const map = new window.google.maps.Map(node, {
      center: data[0],
    });

    const bounds = new window.google.maps.LatLngBounds();
    const markers = [];

    data.forEach(coords => {
      const marker = new window.google.maps.Marker({ position: coords, map });

      markers.push(marker);
      bounds.extend(coords);
    });

    if (centerLocationIndex === undefined) {
      map.fitBounds(bounds);
      map.setZoom(map.getZoom() - 1);

      if (map.getZoom() > 15) {
        map.setZoom(15);
      }
    }

    if (centerLocationIndex !== undefined) {
      map.setCenter(markers[centerLocationIndex].getPosition());
      map.setZoom(15);
    }
  };

  useEffect(() => {
    if (data.length) {
      GoogleMapsLoader.load(handleMapLoad);
    }
  }, [data, centerLocationIndex]);

  return <Map className={className} ref={ref} />;
};
