import React from "react";
import styled, { css } from "styled-components";
import { Icon, Text } from "@artificiallabs/artificial-ui";

import Title from "../Title";

const dividerStyles = css`
  &:after {
    content: "";
    position: absolute;
    background-color: ${({ theme }) => theme.colors.tableBorder};
    height: 1px;
    bottom: 0;
    left: calc(-0.938rem - 0.625rem);
    right: calc(-0.938rem - 0.625rem);
  }
`;

const flexStyles = css`
  display: flex;
  align-items: center;
`;

const Wrapper = styled.div`
  padding: 0.938rem;
  margin-right: 0 !important;
  padding-bottom: 0 !important;s
`;

const SmallText = styled(Text)`
  font-size: 0.6875rem;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: none !important;
  position: relative;
  padding: 0.5rem;

  ${({ withoutDivider }) => !withoutDivider && dividerStyles};
`;

const TickIcon = styled(Icon).attrs(() => ({
  name: "checkbox",
}))`
  width: 0.5rem;
  height: 0.5rem;
  fill: ${({ theme }) => theme.colors.green};
  border: none !important;
  margin-left: 1rem;
`;

const Bar = styled.div`
  background-color: #f2f6f9;
  border-radius: 3px;
  height: 1.625rem;
  display: flex;
`;

const BarItem = styled.div`
  background-color: ${({ color, theme }) => theme.colors[color]};
  width: ${({ width }) => width}%;
  z-index: 2;
  height: 100%;
  margin-right: 0 !important;
  border: none !important;
  border-radius: 3px;

  &:nth-child(2) {
    border-radius: 0 3px 3px 0;
    margin-left: -0.25rem;
    z-index: 1;
  }
`;

const Column = styled.div`
  width: ${({ width }) => width || 32}%;
  border: none !important;
  ${({ withoutFlex }) => !withoutFlex && flexStyles};
`;

const FirstBar = () => (
  <Bar>
    <BarItem color="blue" width={77} />
    <BarItem color="orange" width={18} />
  </Bar>
);

const SecondBar = () => (
  <Bar>
    <BarItem color="blue" width={66} />
    <BarItem color="green" width={15} />
  </Bar>
);

const RiskAppetite = () => (
  <Wrapper>
    <Title>Risk Appetite</Title>
    <Row>
      <Column width={25}>
        <SmallText />
      </Column>
      <Column width={25}>
        <SmallText>Appetite</SmallText>
      </Column>
      <Column width={50}>
        <SmallText>Increase in Risk Capacity</SmallText>
      </Column>
    </Row>
    <Row>
      <Column width={25}>
        <SmallText>UK</SmallText>
      </Column>
      <Column width={25}>
        <TickIcon />
      </Column>
      <Column width={50} withoutFlex>
        <FirstBar />
      </Column>
    </Row>
    <Row withoutDivider>
      <Column width={25}>
        <SmallText>Poland</SmallText>
      </Column>
      <Column width={25}>
        <TickIcon />
      </Column>
      <Column width={50} withoutFlex>
        <SecondBar />
      </Column>
    </Row>
  </Wrapper>
);

export default RiskAppetite;
