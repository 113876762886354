import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {
  Insights,
  LocationValidationPage,
  LossRunPreviewPage,
  LossRunValidationPage,
  UploadPage,
} from "..";
import { routes } from "../../../../config";
import { Header } from "../../../../components";

const IndexPage = () => (
  <>
    <Header />
    <Switch>
      <Route path={routes.upload} component={UploadPage} />
      <Route
        path={routes.lossRunValidation}
        component={LossRunValidationPage}
      />
      <Route path={routes.lossRunPreview} component={LossRunPreviewPage} />
      <Route
        path={routes.locationValidation}
        component={LocationValidationPage}
      />
      <Route path={routes.insights} component={Insights} />
      <Redirect to={routes.upload} />
    </Switch>
  </>
);

export default IndexPage;
