import styled, { css } from "styled-components";
import { Icon } from "@artificiallabs/artificial-ui";
import React from "react";

const IconWrapper = styled.div`
  position: absolute;
  left: 0.5rem;
  background-color: ${({ theme }) => theme.colors.green};
  border-radius: 50%;
  width: 1rem;
  height: 1rem;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    fill: ${({ theme }) => theme.colors.white};
  }
`;

const CheckboxIcon = styled(Icon)`
  width: 0.65rem;
  height: auto;
`;

const CustomIcon = () => (
  <IconWrapper>
    <CheckboxIcon name="checkbox" />
  </IconWrapper>
);

export const Tabs = styled.ul`
  font-family: ${({ theme }) => theme.fonts.inter.regular};
  display: flex;
`;

const activeStyle = css`
  color: ${({ theme }) => theme.colors.charcoal};
  border-bottom-color: ${({ theme }) => theme.colors.blue};
`;

export const TabItem = styled.li`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2.25rem;
  border-bottom-width: 2px;
  border-bottom-color: ${({ theme }) => theme.colors.blueGray};
  border-bottom-style: solid;
  ${({ active }) => active && activeStyle};
  margin-right: 1rem;
  min-width: 9rem;
`;

export const Tab = ({ children, completed, active }) => (
  <TabItem active={active} completed={completed}>
    {completed && <CustomIcon />}
    {children}
  </TabItem>
);
