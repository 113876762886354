import styled from "styled-components";

export default styled.div`
  padding: 0.625rem;
  margin-right: -1.75rem;
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  flex: 1 1 33%;
  height: calc(100vh - 12.25rem);
  border-left: 1px solid ${({ theme }) => theme.colors.tableBorder};
  overflow-y: scroll;
  padding-bottom: 10rem;
`;
