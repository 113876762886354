import React from "react";
import styled, { keyframes } from "styled-components";
import LoaderImg from "./Large.svg";

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const Img = styled.img`
  animation: ${rotate} 2s linear infinite;
  width: 3rem;
  height: 3rem;
`;

const Loader = () => <Img src={LoaderImg} />;

export default Loader;
