export default {
  overall_risk_score: "0.41",
  overall_location_risk_score: "0.48",
  overall_claim_risk_score: "0.33",
  location_risk_data: [
    {
      risk_score: "0.4",
      risk_index: {
        crime: "Low",
        flood: "Moderate to Minimal",
        earthquake: "Low",
        fire: "Low",
      },
      name_or_location: "CANTON ESTATES",
      address: "388 RICKS DR",
      city: "CANTON",
      state: "MS",
      country: "USA",
      post_code: "39046",
    },
    {
      risk_score: "0.57",
      risk_index: {
        crime: "Highest",
        flood: "Moderate to Minimal",
        earthquake: "Low",
        fire: "Low",
      },
      name_or_location: "CEDAR COVE APTS",
      address: "107 CLINTON BLVD",
      city: "CLINTON",
      state: "MS",
      country: "USA",
      post_code: "39056",
    },
    {
      risk_score: "0.48",
      risk_index: {
        crime: "Aboe Average",
        flood: "Moderate to Minimal",
        earthquake: "Low",
        fire: "Low",
      },
      name_or_location: "CHARLESTON PLACE, LLC",
      address: "110 LINCOLN GRN",
      city: "STARKVILLE",
      state: "MS",
      country: "USA",
      post_code: "39759",
    },
  ],
  loss_run_summary: [
    {
      "Year of Account": "2012",
      "Total Net Incurred": "25851.43",
      Limit: "1000000.0",
      "% Limit": "0.03",
    },
    {
      "Year of Account": "2013",
      "Total Net Incurred": "0.0",
      Limit: "1000000.0",
      "% Limit": "0.0",
    },
    {
      "Year of Account": "2015",
      "Total Net Incurred": "120000.1",
      Limit: "61000000.0",
      "% Limit": "0.0",
    },
    {
      "Year of Account": "2016",
      "Total Net Incurred": "0.0",
      Limit: "1000000.0",
      "% Limit": "0.0",
    },
  ],
};
