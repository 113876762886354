import React from "react";
import { H1 } from "@artificiallabs/artificial-ui";
import { useParams } from "react-router-dom";

import Header from "../../components/Header";
import SubmissionDetails from "../../components/SubmissionDetails";
import { SubmissionInsights } from "../../containers";

import locationData from "./locationScheduleMock";
import riskScoreData from "./riskScoreDataMock";

const submission = {
  id: "CA000019843-07",
  insured: {
    name: "Artificial Labs",
  },
  createdAt: "2020-03-11",
  locationScore: 90,
  claimScore: 87,
  overallScore: 85,
};

const SingleSubmission = () => {
  const { id } = useParams();

  return (
    <>
      <Header>
        <H1>
          Submission - <>{id}</>
        </H1>
      </Header>
      <SubmissionDetails
        exposureFile="exposure.xlsx"
        lossRunFile="LR8008.pdf"
        submission={submission}
      />
      <SubmissionInsights
        locationData={locationData}
        riskScoreData={riskScoreData}
      />
    </>
  );
};

export default SingleSubmission;
