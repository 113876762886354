import React, { Fragment } from "react";
import { render } from "react-dom";
import {
  GlobalFonts,
  GlobalStyle,
  ThemeProvider,
} from "@artificiallabs/artificial-ui";

import App from "./components/App";
import Providers from "./components/Providers";
import { theme } from "./config";

render(
  <Providers>
    <ThemeProvider theme={theme}>
      <Fragment>
        <GlobalFonts />
        <GlobalStyle />
        <App />
      </Fragment>
    </ThemeProvider>
  </Providers>,
  document.getElementById("root"),
);
