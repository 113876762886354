import React, { useEffect, useState } from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { get } from "lodash";
import { Text } from "@artificiallabs/artificial-ui";

import { Loader } from "../../../../components";

const Canvas = styled.canvas``;

const Container = styled.div`
  width: 100%;
  flex: 1 1 66%;
`;

const OCR_SCALE = {
  MIN: 0,
  MAX: 1,
};

const EXTRA_SPACE = 2;

const LoaderWrapper = styled.div`
  margin-top: 6rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

function roundRect(ctx, x, y, width, height, r) {
  const radius = { tl: r, tr: r, br: r, bl: r };

  ctx.beginPath();
  ctx.strokeStyle = "#4ECA73";
  ctx.lineWidth = "2";
  ctx.moveTo(x + radius.tl, y);
  ctx.lineTo(x + width - radius.tr, y);
  ctx.quadraticCurveTo(x + width, y, x + width, y + radius.tr);
  ctx.lineTo(x + width, y + height - radius.br);
  ctx.quadraticCurveTo(
    x + width,
    y + height,
    x + width - radius.br,
    y + height,
  );
  ctx.lineTo(x + radius.bl, y + height);
  ctx.quadraticCurveTo(x, y + height, x, y + height - radius.bl);
  ctx.lineTo(x, y + radius.tl);
  ctx.quadraticCurveTo(x, y, x + radius.tl, y);
  ctx.closePath();
  ctx.stroke();
}

const DocumentCanvas = ({ image, fields, isFetching }) => {
  const [canvasHeight, setCanvasHeight] = useState(0);

  const canvasContainer = document.getElementById("canvasContainer");
  const canvasWidth = canvasContainer ? canvasContainer.clientWidth : 1;

  useEffect(() => {
    if (!image || !fields || !fields.length) {
      return;
    }

    const parsedImage = new Image();
    parsedImage.src = `data:image/jpg;base64,${image}`;

    parsedImage.onload = () => {
      const { width, height } = parsedImage;

      const divider = width / canvasWidth;

      setCanvasHeight(height / divider);

      const c = document.getElementById("documentCanvas");
      if (c) {
        const ctx = c.getContext("2d");

        ctx.moveTo(0, 0);

        ctx.drawImage(parsedImage, 0, 0, width / divider, height / divider);

        if (fields && fields.length) {
          fields.forEach(field => {
            if (field) {
              const bounds = get(field, "bounds");

              const topLeftX =
                (bounds.left / (OCR_SCALE.MAX - OCR_SCALE.MIN)) * canvasWidth -
                EXTRA_SPACE;
              const topLeftY =
                (bounds.top / (OCR_SCALE.MAX - OCR_SCALE.MIN)) * canvasHeight -
                EXTRA_SPACE;

              const drawWidth =
                (bounds.width / (OCR_SCALE.MAX - OCR_SCALE.MIN)) * canvasWidth +
                2 * EXTRA_SPACE;
              const drawHeight =
                (bounds.height / (OCR_SCALE.MAX - OCR_SCALE.MIN)) *
                  canvasHeight +
                2 * EXTRA_SPACE;

              roundRect(ctx, topLeftX, topLeftY, drawWidth, drawHeight, 3);
            }
          });
        }
      }
    };

    return () => {
      const c = document.getElementById("documentCanvas");
      if (c) {
        const ctx = c.getContext("2d");
        ctx.clearRect(0, 0, canvasWidth, canvasHeight);
      }
    };
  }, [image, fields, canvasHeight]);

  if (!image || !fields || isFetching) {
    return (
      <LoaderWrapper>
        <Loader />
        <Text> Loading... </Text>
      </LoaderWrapper>
    );
  }

  return (
    <Container id="canvasContainer">
      <Canvas id="documentCanvas" width={canvasWidth} height={canvasHeight} />
    </Container>
  );
};

DocumentCanvas.propTypes = {
  image: PropTypes.string,
  fields: PropTypes.array,
  isFetching: PropTypes.bool,
};

DocumentCanvas.defaultProps = {
  image: null,
  fields: [],
  isFetching: false,
};

export default DocumentCanvas;
