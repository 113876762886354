import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  Text,
  Button,
  Table,
  InputSelect,
} from "@artificiallabs/artificial-ui";
import { useHistory } from "react-router-dom";
import MapSection from "../../components/MapSection";
import { getLocationInformation } from "../../../../api";
import { FullLoader, Icon } from "../../../../components";
import { routes } from "../../../../config";
import {
  setItem,
  getItem,
  getFullAddress,
  mapLocationData,
} from "../../../../helpers";

const StyledIcon = styled(Icon)`
  width: 2em;
`;

const Card = styled.div`
  border: 1px solid #cdd4db;
  padding: 1.5rem;
  margin-top: 4em;
  margin-bottom: 4em;
`;

const Cell = styled.div`
  padding: 0.7rem;
`;

const CenteredCell = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InputSelectCell = styled(Cell)`
  width: 25em;
  white-space: nowrap;
`;

const ActionsCell = styled(Cell)`
  width: 8rem;
`;

const Title = styled(Text)`
  text-transform: uppercase;
  font-size: 11px;
  margin-bottom: 0.5rem;
  line-height: 1.1875rem;
`;

const StyledMap = styled(MapSection)`
  width: 100%;
  height: 25em;
  margin-left: auto;
  margin-right: auto;
`;

const SubmitButton = styled(Button).attrs(() => ({
  kind: "primary",
  type: "submit",
}))`
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 3em;
`;

const columns = [
  {
    id: "location",
    displayName: "Location #",
  },
  {
    id: "fullAddress",
    displayName: "Full Address",
  },
  {
    id: "addressValidated",
    displayName: "Address Validated",
  },
  {
    id: "selectCorrectAddress",
    displayName: "Select Correct Address",
  },
  {
    id: "actions",
    displayName: "Actions",
  },
];

const LocationValidationPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [locationData, setLocationData] = useState([]);
  const [centerLocationIndex, setCenterLocationIndex] = useState();
  const [selectedAddresses, setSelectedAddresses] = useState({});
  const history = useHistory();
  const fileName = getItem("exposureFileName");

  const mappedLocationData = mapLocationData(locationData, selectedAddresses);

  const filteredLocationData = mappedLocationData.filter(
    address => address.lat && address.lng,
  );

  const initialFetch = async () => {
    const response = await getLocationInformation(fileName);

    setIsLoading(false);
    setLocationData(response.data);
  };

  const handleButtonClick = () => history.push(routes.insights);

  const handleSelectChange = locationIndex => selection => {
    setSelectedAddresses({
      ...selectedAddresses,
      [locationIndex]: selection.value,
    });
  };

  useEffect(() => {
    initialFetch();

    return () => setItem("selectedAddresses", selectedAddresses);
  }, [selectedAddresses]);

  const handleShowClick = rowIndex => {
    setCenterLocationIndex(rowIndex);
  };

  if (isLoading) {
    return <FullLoader />;
  }

  return (
    <>
      <Card>
        <Title>Locations</Title>
        <Table
          isStriped
          columns={columns}
          rows={locationData}
          renderData={(row, rowIndex) => column => {
            if (column.id === "location") {
              return <Cell>{rowIndex + 1}</Cell>;
            }

            if (column.id === "fullAddress") {
              return <Cell>{row.full_address}</Cell>;
            }

            if (column.id === "addressValidated") {
              return (
                <CenteredCell>
                  {row.address_found && <StyledIcon name="check" />}
                  {!row.address_found && <StyledIcon name="cross" />}
                </CenteredCell>
              );
            }

            if (column.id === "selectCorrectAddress" && row.select_addresses) {
              return (
                <InputSelectCell>
                  <InputSelect
                    isSearchable={false}
                    options={row.select_addresses.map((item, index) => ({
                      label: getFullAddress(item),
                      value: index,
                    }))}
                    onChange={handleSelectChange(rowIndex)}
                  />
                </InputSelectCell>
              );
            }

            if (column.id === "actions" && mappedLocationData[rowIndex]) {
              return (
                <ActionsCell>
                  <Button
                    isSmall
                    kind="neutral"
                    onClick={() => handleShowClick(rowIndex)}
                  >
                    Show
                  </Button>
                </ActionsCell>
              );
            }

            return false;
          }}
        />
      </Card>
      <Card>
        <StyledMap
          data={filteredLocationData}
          centerLocationIndex={centerLocationIndex}
        />
      </Card>
      <SubmitButton kind="primary" onClick={handleButtonClick}>
        Confirm and continue
      </SubmitButton>
    </>
  );
};

export default LocationValidationPage;
