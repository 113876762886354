import React from "react";
import { Table, Text, Button } from "@artificiallabs/artificial-ui";
import styled, { css } from "styled-components";
import { transparentize } from "polished";
import { get } from "lodash";
import { getUSDValue } from "../../../../../../helpers";

import Title from "../Title";

const greenStyles = css`
  background-color: ${({ theme }) => transparentize(0.9, theme.colors.green)};
  color: #349551;
`;

const redStyles = css`
  color: ${({ theme }) => theme.colors.red};
`;

const Card = styled.div`
  border: 1px solid #cdd4db;
  padding: 1.5rem;
`;

const RecordWrapper = styled.div`
  display: flex;
  align-items: center;

  margin-top: ${({ withMarginTop }) => withMarginTop && "1rem"};
  margin-bottom: 0.375rem;
`;

const CustomItem = styled.div`
  min-height: 3rem;
  display: flex;
  align-items: center;
  padding: 0 0.625rem;

  ${({ green }) => (green ? greenStyles : redStyles)};
`;

const TextWithSpace = styled(Text)`
  margin-left: 1.75rem;
  margin-right: 3rem;
`;

const columns = [
  {
    id: "Year of Account",
    displayName: "Year of Account",
  },
  {
    id: "Total Net Incurred",
    displayName: "Total Net Incurred",
  },
  {
    id: "Limit",
    displayName: "Limit",
  },
  {
    id: "% Limit",
    displayName: "% Limit",
  },
];

const LossHistory = ({ data }) => {
  const mappedData = data.map(item => ({
    ...item,
    "% Limit": (parseFloat(item["% Limit"]) * 100).toFixed(2),
    "Total Net Incurred": getUSDValue(item["Total Net Incurred"]),
    Limit: getUSDValue(item["Limit"]),
  }));

  const numYears = mappedData.length;
  const average =
    data.reduce(
      (prev, next) => prev + parseFloat(next["Total Net Incurred"]),
      0,
    ) / numYears;

  return (
    <Card>
      <Title> loss history </Title>
      <Table isStriped columns={columns} rows={mappedData} />
      <RecordWrapper withMarginTop>
        <Text>
          Total available years:
          <TextWithSpace as="span">{numYears}</TextWithSpace>
        </Text>
        <Text>
          Average Net loss per year:
          <TextWithSpace as="span">{getUSDValue(average)}</TextWithSpace>
        </Text>
      </RecordWrapper>
    </Card>
  );
};

export default LossHistory;
